import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { Form, Input } from "antd"

import { getConfig } from "@components/Config"
import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const PLATFORM_DOMAIN = getConfig("PLATFORM_DOMAIN")


const DomainInput = ({
  form,
  path,
  itemProps,
  inputProps,
}) => {
  const { name, initialValue, ...restItemProps } = itemProps
  const { addonAfter = PLATFORM_DOMAIN } = inputProps
  const addonBefore = "https://"

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const sourceValue = Form.useWatch(path, form)
  const inputSourceValue = Form.useWatch(inputName, form)

  const setValue = useCallback(() => {
    const value = form.getFieldValue(path)

    if (!value) {
      return
    }

    const formattedValue = value.replace(`.${addonAfter}`, '')

    form.setFieldValue(inputPath, formattedValue)
  }, [
    form,
    path,
    inputPath,
    addonAfter,
  ])

  useEffect(() => {
    setValue()
  }, [
    setValue,
    sourceValue,
    inputSourceValue,
  ])

  const onChange = event => {
    const formattedValue = `${event.target.value}.${addonAfter}`

    form.setFieldValue(path, formattedValue)
  }

  return (
    <div>
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Form.Item>

      <Form.Item
        key="input"
        name={inputName}
        {...restItemProps}
      >
        <Input
          addonAfter={`.${addonAfter}`}
          addonBefore={addonBefore}
          {...inputProps}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  )
}

DomainInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default DomainInput
