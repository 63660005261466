import React, { useRef } from "react"
import { Row, Col } from "antd"

import Page from "@components/Page"
import { W1 } from "@components"

import ReportCard from "./ReportCard"
import ProjectsReportModal from "./ProjectsReportModal"
import InvestmentsReportModal from "./InvestmentsReportModal"
import OrganizationsReportModal, { OrganizationsReportForm } from "./OrganizationsReportModal"
import DistributionsReportModal, { DistributionsReportForm } from "./DistributionsReportModal"

const LABEL_TITLE = "Reports"

const LABEL_PROJECTS = "Projects"
const LABEL_INVESTMENTS = "Investments"
const LABEL_ORGANIZATIONS = "Organizations"
const LABEL_DISTRIBUTIONS = "Distributions, Monthly"

const LABEL_PROJECTS_SUMMARY = `Projects with equity raised and total amounts
  distributed for each.`
const LABEL_INVESTMENTS_SUMMARY = `Investments with investor profiles details
  for each portal.`
const LABEL_ORGANIZATIONS_SUMMARY = `Distribution $ amounts for each portal for
  selected date range.`
const LABEL_DISTRIBUTIONS_SUMMARY = `Distributions for each portal project for
  selected month.`


const ReportsPage = () => {
  const projectsReportModal = useRef({})
  const investmentsReportModal = useRef({})
  const organizationsReportModal = useRef({})
  const distributionsReportModal = useRef({})

  const openProjectsModal = () =>
    projectsReportModal.current.open()

  const openInvestmentsModal = () =>
    investmentsReportModal.current.open()

  const openDistributionsModal = ({ date }) =>
    distributionsReportModal.current.open(date)

  const openOrganizationsModal = ({ dateRange }) =>
    organizationsReportModal.current.open(dateRange)

  return (
    <Page
      title={LABEL_TITLE}
    >
      <Row gutter={[ W1, W1 ]}>
        <Col span={8}>
          <ReportCard
            title={LABEL_ORGANIZATIONS}
            summary={LABEL_ORGANIZATIONS_SUMMARY}
            renderAction={() => <OrganizationsReportForm onSubmit={openOrganizationsModal} />}
          />
        </Col>

        <Col span={8}>
          <ReportCard
            title={LABEL_PROJECTS}
            summary={LABEL_PROJECTS_SUMMARY}
            onClick={openProjectsModal}
          />
        </Col>

        <Col span={8}>
          <ReportCard
            title={LABEL_INVESTMENTS}
            summary={LABEL_INVESTMENTS_SUMMARY}
            onClick={openInvestmentsModal}
          />
        </Col>

        <Col span={8}>
          <ReportCard
            title={LABEL_DISTRIBUTIONS}
            summary={LABEL_DISTRIBUTIONS_SUMMARY}
            renderAction={() => <DistributionsReportForm onSubmit={openDistributionsModal} />}
          />
        </Col>
      </Row>

      <ProjectsReportModal
        modal={projectsReportModal}
      />

      <DistributionsReportModal
        modal={distributionsReportModal}
      />

      <OrganizationsReportModal
        modal={organizationsReportModal}
      />

      <InvestmentsReportModal
        modal={investmentsReportModal}
      />
    </Page>
  )
}

export default ReportsPage
