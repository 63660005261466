import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Button, Dropdown, Form, Progress, Typography } from "antd"
import { useOutletContext, useParams } from "react-router-dom"
import { DownOutlined } from "@ant-design/icons"

import { ImportModal } from "@components/Page"
import { requestBatch } from "@api"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { validateDistributionTransactionOperation as validateOperation } from "@api/services/transactions"

import useActions from "./helpers/useActions"
import useSetRequestErrors from "./helpers/useSetRequestErrors"
import SelectDocumentButton from "./SelectDocumentButton"
import validateTransactions from "../TransactionCreateModal/helpers/validateTransactions"
import DistributionsCollection from "./DistributionsCollection/DistributionsCollection"
import computeTransactionsToCreate from "./helpers/computeTransactionsToCreate"

const { Text } = Typography

const LABEL_TITLE = "Import Distributions"
const LABEL_CREATE = "Create Distributions"
const LABEL_SUCCESS = "Distributions are created"
const LABEL_BACKFILL = "Backfill Distributions"
const LABEL_VALIDATING = "Validating"
const LABEL_IMPORT_ERRORS = "Import File Errors"
const LABEL_VALIDATION_ERRORS = "Distribution Errors"

const VALIDATE_ERROR = "UnprocessibleConditionError"


const DistributionsImportModal = ({
  modal,
  onSuccess,
  investments,
  isInvalidBankAccount,
  hasDistributionsAccount,
}) => {
  const collectionRef = useRef({})
  const [ form ] = Form.useForm()

  const { id: projectId } = useParams()
  const { request, showSuccessMessage } = useAppContext()

  const { project } = useOutletContext()

  const [ errors, setErrors ] = useState()
  const [ progress, setProgress ] = useState()
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ isValidating, setIsValidating ] = useState(false)
  const [ hasSubmitErrors, setHasSubmitErrors ] = useState(false)
  const [ isSubmitEnabled, setIsSubmitEnabled ] = useState(false)
  const [ validateProgress, setValidateProgress ] = useState()

  const setRequestErrors = useSetRequestErrors(setErrors)

  const { name: projectName } = project

  const resetModal = () => {
    setProgress()
    setIsValidating(false)
    setValidateProgress()
    setIsSubmitEnabled(false)
    setHasSubmitErrors(false)

    form.resetFields()

    collectionRef.current.setNewTransactions([])
  }

  const onCloseModal = () => {
    setErrors()
    resetModal()
  }

  const onSubmit = async (operation, isBackfill = false) => {
    const isFormValid = await form.validateFields()
      .then(() => true)
      .catch(() => false)

    if (!isFormValid) {
      return
    }

    const date = form.getFieldValue('date')

    const transactionCollections = collectionRef.current.getTransactions()
    const transactionList = computeTransactionsToCreate(transactionCollections, date, investments, projectName)

    const transactionErrors = await validateTransactions(request, projectId, transactionList, investments, isBackfill)
    const hasTransactionErrors = transactionErrors.length > 0

    if (hasTransactionErrors) {
      setHasSubmitErrors(true)
      setErrors(transactionErrors)

      return
    }

    const transactionCreateMutations = transactionList.map(item => ({
      parameters : {
        mutation: {
          ...item,
        }
      }
    }))

    const isNotBackfill = !isBackfill

    if (isNotBackfill) {
      const validateTransaction = parameters =>
        request(validateOperation, parameters, [ VALIDATE_ERROR ])

      setIsValidating(true)

      const requestErrors = await requestBatch(
        setValidateProgress,
        transactionCreateMutations,
        parameters => validateTransaction(parameters),
        setRequestErrors
      )

      setIsValidating(false)

      const hasErrors = requestErrors.length > 0

      if (hasErrors) {
        setHasSubmitErrors(true)
        return
      }
    }

    setIsSubmitting(true)

    const createTransaction = parameters =>
      request(operation, parameters)

    await requestBatch(
      setProgress,
      transactionCreateMutations,
      parameters => createTransaction(parameters),
    )

    await onSuccess(isBackfill)

    setIsSubmitting(false)

    showSuccessMessage(LABEL_SUCCESS)
    collectionRef.current.setNewTransactions([])

    resetModal()
  }

  const onUpdateCollection = items =>
    setIsSubmitEnabled(items.length > 0)

  const {
    defaultImport: create,
    registerImport: backfill
  } = useActions(onSubmit)

  const canCreateTransactions =
    hasDistributionsAccount &&
    !isInvalidBankAccount

  const submitButtonLabel = canCreateTransactions
    ? LABEL_CREATE
    : LABEL_BACKFILL

  const loading = isSubmitting || isValidating

  const submitButtonProps = {
    type: "primary",
    trigger: ["click"],
    onClick: backfill,
    loading,
    disabled: !isSubmitEnabled,
  }

  if (canCreateTransactions) {
    const items = [
      {
        key: "1",
        label: LABEL_BACKFILL,
        onClick: backfill
      },
    ]

    submitButtonProps.icon = <DownOutlined />
    submitButtonProps.menu = { items }
    submitButtonProps.onClick = create
  }

  const ButtonComponent = hasDistributionsAccount
    ? Dropdown.Button
    : Button

  const setImportErrors = values => {
    setHasSubmitErrors(false)
    setErrors(values)
  }

  const modalHeader = (
    <>
      <SelectDocumentButton
        form={form}
        onSuccess={newTransactions => collectionRef.current.setNewTransactions(newTransactions)}
        setErrors={setImportErrors}
      />

      <ButtonComponent {...submitButtonProps}>
        {submitButtonLabel}
      </ButtonComponent>
    </>
  )

  const errorProps = {
    errorTitle: LABEL_IMPORT_ERRORS
  }

  if (hasSubmitErrors) {
    errorProps.errorTitle = LABEL_VALIDATION_ERRORS
    errorProps.errorPrefix = ""
  }

  return (
    <ImportModal
      key={projectId}
      modal={modal}
      title={LABEL_TITLE}
      header={modalHeader}
      errors={errors}
      onClose={onCloseModal}
      progress={progress}
      {...errorProps}
    >
      {
        isValidating && (
          <>
            <Progress percent={validateProgress} />
            <Text>{LABEL_VALIDATING}</Text>
          </>
        )
      }

      {
        !isValidating && (
          <DistributionsCollection
            collection={collectionRef}
            onUpdateCollection={onUpdateCollection}
          />
        )
      }
    </ImportModal>
  )
}

DistributionsImportModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  isInvalidBankAccount: PropTypes.bool.isRequired,
  hasDistributionsAccount: PropTypes.bool.isRequired,
}

export default DistributionsImportModal
