import { TYPE_STRING, TYPE_NONE } from "@components/Form"

const LABEL_DOMAIN = "Custom Domain"

const getInputs = () => {
  return [
    {
      name: "emailIdentity",
      label: "",
      schema: [
        {
          name: "domain",
          type: TYPE_STRING,
          label: LABEL_DOMAIN,
        },
        {
          name: 'isVerified',
          type: TYPE_NONE
        },
        {
          name: 'dnsRecords',
          type: TYPE_NONE
        }
      ]
    }
  ]
}

export default getInputs
