import dayjs from "dayjs"
import { useOutletContext } from "react-router-dom"

import { getConfig } from "@components/Config"
import operationRequest from "@components/Authorization/request/operationRequest"
import { getCurrencyValue } from "@components/Amount"
import { useExportProgress } from "@components/Export"
import { getIndexOperationParameters } from "@modules/backstage/transactions/TransactionsTable/helpers/useIndexOperationParameters"
import { readTransactionReportOperation } from "@api/services/transactions"

const LABEL_DISTRIBUTIONS = "Total Distributions"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_ORGANIZATION_NAME = "Organization Name"

const consoleOrganizationId = getConfig("consoleOrganizationId")


const useOrganizationsReport = (onReady) => {
  const fileName = "oraganizations_report"

  const { organizations } = useOutletContext()

  const targetOrganizations = organizations
    .filter(({ id }) => id !== consoleOrganizationId)

  const totalCount = targetOrganizations.length

  const getRows = async ({ authorizeOrganization, shouldStop, updateProgress }, dateRange) => {
    const [ startDay, endDay ] = dateRange

    const endDate = dayjs(endDay).endOf('month')
    const startDate = dayjs(startDay).startOf('month')

    const parameters = getIndexOperationParameters({
      dateRange: [ startDate, endDate ]
    })

    const getOragnizationDistributionsAmount = async (organizationId) => {
      const authorization = await authorizeOrganization(organizationId)

      const headers = { authorization }
      const operation = readTransactionReportOperation

      const { data: transactionReport } = await operationRequest({
        headers,
        operation,
        parameters,
      })

      const { distributionsAmount } = transactionReport

      return distributionsAmount
    }

    const rows = []

    let index = 1

    for (const organization of targetOrganizations) {
      if (shouldStop()) {
        return
      }

      const {
        id: organizationId,
        name: organizationName,
      } = organization

      // eslint-disable-next-line no-console
      console.info('Export organization', `${organizationId} - ${organizationName}`)

      const amount = await getOragnizationDistributionsAmount(organizationId)
      const distributionsAmount = getCurrencyValue(amount)

      const row = {
        [LABEL_ORGANIZATION_ID]: organizationId,
        [LABEL_ORGANIZATION_NAME]: organizationName,
        [LABEL_DISTRIBUTIONS]: distributionsAmount,
      }

      rows.push(row)

      updateProgress(index, totalCount)
      index++
    }

    return rows
  }

  return useExportProgress({ onReady, fileName, getRows })
}

export default useOrganizationsReport
